import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate"

export default createStore({
  state: {
    user: null,
    currency: "BTC",
    readableNotifications: false,
    isBet: false
  },
  getters: {
    getUserData(state) {
      return state.user
    },
    getCurrency(state) {
      return state.currency
    },
    getReadable(state) {
      return state.readableNotifications
    },
    getIsBet(state) {
      return state.isBet
    }
  },
  mutations: {
    setUserData(state, data) {
      state.user = data
    },
    setCurrency(state, data) {
      state.currency = data
    },
    setReadable(state, data) {
      state.readableNotifications = data
    },
    setIsBet(state, data) {
      state.isBet = data
    },
    logout(state) {
      state.user = null
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
