<template>
  <div class="errorBackground">
    <div class="errorBlock">
			<p class='errorHeadText'>Произошла ошибка при загрузке сайта.</p>
			<p class='errorDescText'>Попробуйте обновить страницу или обратитесь в техническую поддержку</p>
		</div>
  </div>
</template>

<script>
export default {
  name: 'ErrorFullPage',
  data() {
    return {
      
    }
  },
  methods: {
    
  },
  mounted() {
    this.user = this.$store.getters.getUserData
  }
}
</script>

<style scoped>
.errorBackground {
	position: absolute;
	left: 0;
	top: 0;
	background-color: #00000076;
	backdrop-filter: blur(7px);
	width: 100vw;
	height: 100vh;
	z-index: 10000;
	display: flex;
	align-items: center;
	justify-content: center;
}
.errorBlock {
	width: 30%;
	background-color: #0E0E0E;
	border: 1px solid #FFFFFF0D;
	border-radius: 10px;
	padding: 3%;
}
.errorHeadText {
	font-size: 22px;
	font-weight: 600;
	margin-bottom: 15px;
}
.errorDescText {
	font-weight: 600;
	color: #727272;
}
@media (max-width:968px) {
	.errorBlock {
		width: 85%;
		padding: 5%;
	}
}
</style>
