<template>
  <div class='dashboard'>
    <header class='dashboardHeader'>
      <div class='selectModeChart'>
        <p id='1m' @click='setTimeFilter'>1m</p>
        <p id='30m' @click='setTimeFilter'>30m</p>
        <p id='1h' class='timeFilter' @click='setTimeFilter'>1h</p>
      </div>
      <div class='headerRight'>
        <div class='deposit' @click="deposit = true"><p>Пополнить</p></div>
        <DepositComponent v-if="deposit" @onCloseDeposit="closeDeposit" style="z-index: 10000;"/>
        <div class='balance'>
          <p style='margin-right: 5px;'>Баланс</p>
          <p style='color: #727272;'>{{this.$store.getters.getUserData.balance}}₽</p>
        </div>
      </div>
		</header>
    <div class='chart'>
      <div class='titleChart'>
        <div class='firstTitleChart'>
          <p>{{ currency }}/USD</p>
          <p>•</p>
          <p>{{ timeFilter }}</p>
          <p>•</p>
          <p>Uniswap</p>
        </div>
        <div class='secondTitleChart'>
          <p class='typeHeadCandle'>O</p><p :class='lastCandle[5] + "HeadCandle"'>{{ lastCandle[1].toFixed(3) }}</p>
          <p class='typeHeadCandle'>H</p><p :class='lastCandle[5] + "HeadCandle"'>{{ lastCandle[2].toFixed(3) }}</p>
          <p class='typeHeadCandle'>L</p><p :class='lastCandle[5] + "HeadCandle"'>{{ lastCandle[3].toFixed(3) }}</p>
          <p class='typeHeadCandle'>C</p><p :class='lastCandle[5] + "HeadCandle"'>{{ lastCandle[4].toFixed(3) }}</p>
        </div>
      </div>
      <highcharts class="highcharts-dark" :options="chartOptions" :constructor-type="'stockChart'" ref="myChart"></highcharts>
    </div>
  </div>
  <div class='bottomChart'>
    <div class='bottomBet'>
      <input type='number' placeholder='Сумма в рублях' v-model='betSum'/>
      <div class='bottomButtons'>
        <div class='bottomChangeButtons' @click="changeSum"><p>+</p></div>
        <div class='bottomChangeButtons' @click="changeSum"><p>—</p></div>
      </div>
    </div>
    <div class='bottomBet'>
      <input type='number' placeholder='Время сделки в секундах' v-model='betTime'/>
      <div class='bottomButtons'>
        <div class='bottomChangeButtons' @click="changeTime"><p>+</p></div>
        <div class='bottomChangeButtons' @click="changeTime"><p>—</p></div>
      </div>
    </div>
    <div class='betButtons'>
      <div class='betUpButton' id='up' @click='bet'>
        <p>Вверх</p>
      </div>
      <div class='betDownButton' id='down' @click='bet'>
        <p>Вниз</p>
      </div>
    </div>
    <div class='possibleIncome'>
      <p>Возможный доход</p>
      <p style='color: #727272;'>{{ betSum * 2 }}₽</p>
    </div>
  </div>
  <div class='dashboardMobile'>
    <div class='chart'>
      <div class='titleChart'>
        <div class='selectModeChart'>
          <p id='1m' @click='setTimeFilter'>1m</p>
          <p id='30m' @click='setTimeFilter'>30m</p>
          <p id='1h' class='timeFilter' @click='setTimeFilter'>1h</p>
        </div>
        <div class='firstTitleChart'>
          <p>{{ currency }}/USD</p>
          <p>•</p>
          <p>{{ timeFilter }}</p>
          <p>•</p>
          <p>Uniswap</p>
        </div>
        <div class='secondTitleChart'>
          <div style='display: flex;'>
            <p class='typeHeadCandle'>O</p><p :class='lastCandle[5] + "HeadCandle"'>{{ lastCandle[1].toFixed(3) }}</p>
            <p class='typeHeadCandle'>H</p><p :class='lastCandle[5] + "HeadCandle"'>{{ lastCandle[2].toFixed(3) }}</p>
          </div>
          <div style='display: flex;'>
            <p class='typeHeadCandle'>L</p><p :class='lastCandle[5] + "HeadCandle"'>{{ lastCandle[3].toFixed(3) }}</p>
            <p class='typeHeadCandle'>C</p><p :class='lastCandle[5] + "HeadCandle"'>{{ lastCandle[4].toFixed(3) }}</p>
          </div>
        </div>
      </div>
      <highcharts class="highcharts-dark" :options="chartOptions" :constructor-type="'stockChart'"></highcharts>
    </div>
  </div>
  <div class='bottomChartMobile'>
    <div class='possibleIncome'>
      <p>Возможный доход</p>
      <p style='color: #727272;'>{{ betSum * 2 }}₽</p>
    </div>
    <div class='betButtons'>
      <div class='betUpButton' id='up' @click='bet'>
        <p>Вверх</p>
      </div>
      <div class='betDownButton' id='down' @click='bet'>
        <p>Вниз</p>
      </div>
    </div>
    <div class='bottomBet'>
      <input type='number' placeholder='Время сделки в секундах' v-model='betTime'/>
      <div class='bottomButtons'>
        <div class='bottomChangeButtons' @click="changeTime"><p>+</p></div>
        <div class='bottomChangeButtons' @click="changeTime"><p>—</p></div>
      </div>
    </div>
    <div class='bottomBet'>
      <input type='number' placeholder='Сумма в рублях' v-model='betSum'/>
      <div class='bottomButtons'>
        <div class='bottomChangeButtons' @click="changeSum"><p>+</p></div>
        <div class='bottomChangeButtons' @click="changeSum"><p>—</p></div>
      </div>
    </div>
    
    <DepositComponent v-if="deposit" @onCloseDeposit="closeDeposit" style="z-index: 10000;"/>
    <div class='balance'>
      <p style='margin-right: 5px;'>Баланс</p>
      <p style='color: #727272;'>{{this.$store.getters.getUserData.balance}}₽</p>
    </div>
    <div class='deposit' @click="deposit = true"><p>Пополнить</p></div>
  </div>
</template>
<script>
import DepositComponent from '@/components/DepositComponent.vue'
import axios from 'axios'
import Highcharts from 'highcharts'
import stockInit from 'highcharts/modules/stock'
import { useToast } from 'vue-toastification'
stockInit(Highcharts)
export default {
  name: 'DashBoard',
  components: {
    DepositComponent
  },
  data() {
    return {
      betSum: null,
      width: 0,
      height: 0,
      betTime: null,
      timeFilter: "1h",
      candlesInterval: null,
      deposit: false,
      skipCandle: false,
      candle: [],
      lastCandle: [0,0,0,0,0, "green"],
      currency: this.$store.getters.getCurrency,
      dataSet: [],
      plotBackColor: '00c896',
      balance: this.$store.getters.getUserData.balance,
      chartOptions: {
          height: null,
          chart: {
            backgroundColor: "transparent",
            panning: {
              enabled: true,
              type: 'x'
            },
            
            pinchType: "x",
            events: {
              load: (function(self) {
                return function() {
                  self.chart = this; // saving chart reference in the component
                };
              })(this),
              
            }
          },
          tooltip: {
            followTouchMove: false
          },
          rangeSelector : {
              enabled : false,
              
          },
          navigator: {
            enabled: false
          },
          scrollbar: { enabled: false },
          subtitle: {
              style: {
                  color: '#ff0000',
                  font: 'bold 12px "Trebuchet MS", Verdana, sans-serif'
              }
          },
          plotOptions: {
              candlestick: {
                  color: '#FF3B30',
                  lineColor: '#FF3B30',
                  upColor: '#00C896',
                  upLineColor: '#00C896'
              }
          },
          yAxis: {
              gridLineWidth: 0,
              plotLines: [
                {
                  value: 69300, // Начальное значение уровня линии (например, цена закрытия последней свечи)
                  color: "white",
                  zIndex: 5,
                  width: 1,
                  dashStyle: "dash",
                  label: {
                    text: "100Р ↓",
                    align: "left",
                    y: -20,
                    useHTML: true, 
                    // eslint-disable-next-line
                    text: "150РУБАСОВ ВНИЗ",
                    style: {
                      backgroundColor: "yellow",
                      padding: "5px",
                      borderRadius: "7px"
                    }
                  }
                }
              ],
              labels: {
                step: 1,
                format: '${text}',
                align: 'left',
                style: {
                  color: "#C4C4C4",
                  font: '700 16px "Proxima Nova Regular", sans-serif',
                }
              },
          },
          xAxis: {
              overscroll: '30px',
              type: 'datetime',
              ordinal: false,
              minRange: 1,
              events: {
                
              },
              panningEnabled: true,
              labels: {
                style: {
                  color: "#C4C4C4",
                  font: '700 16px "Proxima Nova Regular", sans-serif',
                }
              },
          },
          series: [
          {
            type: 'candlestick',
            name: 'Information',
            data: [[0,0,0,0,0]],
            tooltip: {
              valueDecimals: 6
            },
            dataGrouping: {
              enabled: false
            }
          }]
        }
    }
  },
  setup() {
    const toast = useToast()
    return { toast }
  },
  mounted() {
    this.removeWatermark()
    this.getDimensions()
    window.addEventListener('resize', this.getDimensions);
    this.loadCandles(this.timeFilter, this.currency)
  },
  methods: {
    changeSum(event) {
      let button = event.target.closest('div')
      if (button.children[0].innerHTML == "+") this.betSum += 100
      else if (button.children[0].innerHTML == "—" && this.betSum - 100 > 0) this.betSum -= 100
      else if (this.betSum - 100 <= 0) this.betSum = null
    },
    getDimensions() {
      this.width = document.documentElement.clientWidth;
      this.height = document.documentElement.clientHeight;
      if (this.width <= 968) this.chartOptions.yAxis.labels.enabled = false
      else this.chartOptions.yAxis.labels.enabled = true
    },
    changeTime(event) {
      let button = event.target.closest('div')
      if (button.children[0].innerHTML == "+") this.betTime += 10
      else if (button.children[0].innerHTML == "—" && this.betTime - 10 > 0) this.betTime -= 10
      else if (this.betTime - 10 <= 0) this.betTime = null
    },
    showPasswordButton() {
      this.showPassword = !this.showPassword
      let pass = document.getElementById('password')
      if (this.showPassword) pass.type = 'text'
      else pass.type='password'
    },
    changeCurrency() {
      this.currency = this.$store.getters.getCurrency
      this.loadCandles(this.timeFilter, this.currency)
    },
    bet(event) {
      if (this.skipCandle) this.toast.error("Ожидайте конца ставки", {timeout: 2000})
      else if (this.betSum < 1 || this.betSum > 9999999) this.toast.error("Измените сумму ставки", {timeout: 2000})
      else if (this.betTime < 10 || this.betTime > 100) this.toast.error("Измените время ставки", {timeout: 2000})
      else {
        this.skipCandle = true
        this.$store.commit('setIsBet', true)
        let betMode = event.target.closest('div')
        betMode = betMode.id
        axios.post(`https://${process.env.VUE_APP_SERVERHOST}/api/bet/`, 
          { 
              amount: this.betSum,
              time: this.betTime,
              currency: this.$store.getters.getCurrency,
              direction: betMode,
          }, 
          {
            headers: {
                Authorization: 'Bearer ' + this.$store.state.user.token
              }
          })
        .then(res => {
          let niceCandle = res.data.result
          let niceCandleArray = [niceCandle.timestamp, niceCandle.open, niceCandle.high, niceCandle.low, niceCandle.close]
          this.toast.info("Ожидаем конца ставки...", {timeout: this.betTime*1000})
          this.chartOptions.yAxis.plotLines[0].value = this.chartOptions.series[0].data[this.chartOptions.series[0].data.length-1][4]
          if (betMode == "up") {
            this.chartOptions.yAxis.plotLines[0].color = "#00c795"
            this.chartOptions.yAxis.plotLines[0].label.style.backgroundColor = "#00c795"
            this.chartOptions.yAxis.plotLines[0].label.text = this.betSum + "₽ ↑"
          }
          else {
            this.chartOptions.yAxis.plotLines[0].color = "#ff3b30"
            this.chartOptions.yAxis.plotLines[0].label.style.backgroundColor = "#ff3b30"
            this.chartOptions.yAxis.plotLines[0].label.text = this.betSum + "₽ ↓"
          }
          this.candle = niceCandleArray
          
          setTimeout(() => {
            
            // this.chartOptions.series[0].data.push(niceCandleArray)
            this.skipCandle = false
            axios.get(`https://${process.env.VUE_APP_SERVERHOST}/api/users/me`, {
            headers: {
              Authorization: 'Bearer ' + this.$store.state.user.token
            }
            })
            .then(res => {
              // if (this.$store.getters.getUserData.balance < res.data.result.balance) this.toast.success(`Ваш доход составил ${this.betSum}₽`)
              // else this.toast.error(`Ваш проигрыш ${this.betSum}₽`)
              this.chartOptions.yAxis.plotLines[0].value = 0
              this.$store.commit('setUserData', res.data.result)
            })
          }, this.betTime * 1000)

          })
          .catch(err => {
            this.toast.error(err.response.data.message, {timeout: 3000})
            this.skipCandle = false
          })
      }
      
    },
    removeWatermark() {
        let t = document.querySelector('.highcharts-credits')
        t.remove()
        t = document.querySelector('.highcharts-credits')
        t.remove()
      },
    loadCandles(time, currency) {
      clearInterval(this.candlesInterval)
      this.chartOptions.series[0].data = []
      this.timeFilter = time
      let now = Math.round(new Date())
      let hourAgo = new Date()
      let hourAhead = ((Math.round(now / 1000)) + 3600) * 1000
      switch(time) {
        case '1h':
          hourAgo = ((Math.round(now / 1000)) - 3600) * 1000
          break
        case '30m':
          hourAgo = ((Math.round(now / 1000)) - 1800) * 1000
          break
        case '1m':
          hourAgo = ((Math.round(now / 1000)) - 60) * 1000
          break
      }
        axios.get(`https://${process.env.VUE_APP_SERVERHOST}/api/bet/candles/${currency}`, 
        {
          params : 
          {
            start: hourAgo.toString(),
            end: hourAhead.toString(),
          },
          headers: {
              Authorization: 'Bearer ' + this.$store.state.user.token
            }
        })
        .then(res => {
          let temp = {}
          let firstArray = []
          if (time == "1h") {
            firstArray = res.data.result.slice(0,res.data.result.length/2)
            this.dataSet = res.data.result.slice(res.data.result.length/2)
          }
          else if (time == "30m") {
            firstArray = res.data.result.slice(0,res.data.result.length/3)
            this.dataSet = res.data.result.slice(res.data.result.length/3)
          }
          else if (time == "1m") {
            firstArray = res.data.result.slice(0,res.data.result.length/60)
            this.dataSet = res.data.result.slice(res.data.result.length/60)

          }
          
          for(let i = 0;i<firstArray.length; i++){
            temp = [
              res.data.result[i].timestamp,
              res.data.result[i].open,
              res.data.result[i].high,
              res.data.result[i].low,
              res.data.result[i].close
            ]
            this.chartOptions.series[0].data.push(temp)
            this.lastCandle = temp
            if (this.chartOptions.series[0].data[this.chartOptions.series[0].data.length-1][4] > this.lastCandle[4]) this.lastCandle[5] = "green"
            else this.lastCandle[5] = "red"
          }
          let i = 0
          let tempArr = []

          let ch = this.$refs.myChart.chart
          ch.xAxis[0].setExtremes(null, null)
          ch.yAxis[0].setExtremes(null, null)
          this.candlesInterval = setInterval(() => {
            tempArr = [this.dataSet[i].timestamp, this.dataSet[i].open, this.dataSet[i].high, this.dataSet[i].low, this.dataSet[i].close]
            this.chartOptions.series[0].data.push(tempArr)
            this.lastCandle = tempArr
            if (this.chartOptions.series[0].data[this.chartOptions.series[0].data.length-2][4] < this.lastCandle[4]) this.lastCandle[5] = "green"
            else this.lastCandle[5] = "red"
            if (this.skipCandle) {
              let can = Math.round(this.candle[0] / 1000)
              let bbb = this.dataSet.findIndex(x => Math.round(x.timestamp / 1000) === can);
              this.dataSet[bbb] = {
                timestamp: this.candle[0],
                open: this.candle[1],
                high: this.candle[2],
                low: this.candle[3],
                close: this.candle[4],
              } 
            }
            // ch.yAxis[0].setExtremes(null, null)
            i++
            if (i == this.dataSet.length) clearInterval(this.candlesInterval)
            this.$refs.myChart.chart.series[0].setData(this.chartOptions.series[0].data, true);
            this.moveChartRight()
            // let ch = this.$refs.myChart.chart
              // ch.xAxis[0].setExtremes(this.chartOptions.series[0].data[0][0], this.chartOptions.series[0].data[this.chartOptions.series[0].data.length - 1][0]);
          }, 5000);
          
        })
      },
      setTimeFilter(event) {
        let t = document.querySelectorAll('.selectModeChart')
        for (let ts of t) {
          ts = ts.children
          for (let el of ts) {
            el.className = ""
          }
        }
        
        let pick = document.querySelectorAll(`[id="${event.target.id}"]`)
        for (let p of pick) p.className = "timeFilter"
        this.chartOptions.series[0].data = []
        this.loadCandles(event.target.id, this.currency)
        
    },
    closeDeposit() {
      this.deposit = false
    },
    moveChartRight() {
      const chart = this.$refs.myChart.chart;

      // Получаем текущие экстремумы оси X
      const xAxis = chart.xAxis[0];
      const min = xAxis.min;
      const max = xAxis.max;

      // Увеличиваем экстремумы, чтобы сдвинуть график вправо
      const shiftAmount = 5 * 1000; // Сдвиг на 1 минуту
      xAxis.setExtremes(min + shiftAmount, max + shiftAmount);
    },
  }
}
</script>
<style scoped>
.dashboard {
	width:70%;
	margin: 0 auto;
  margin-top:20px;
	border-radius: 10px;
  border: 1px solid #FFFFFF0D;
	background: #C4C4C405;
  padding: 2%;
  height: 80%;
  margin-bottom:10px;
}
.dashboardMobile {
	width:90%;
	margin: 0 auto;
  margin-top:20px;
	border-radius: 10px;
  border: 1px solid #FFFFFF0D;
	background: #C4C4C405;
  padding: 2%;

  margin-bottom:10px;
  display: none;
}
.dashboardHeader {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width:100%;
  align-items: center;
}
.chart {
  width:100%;
  margin: 0 auto;
  background-image: url('../assets/chartBackground.png');
  background-size: 100%;
  background-position: -5vw 0vh;
}
.bottomChart {
  width: 70%;
  margin: 0 auto;
	border-radius: 10px;
  border: 1px solid #FFFFFF0D;
	background: #C4C4C405;
  padding: 1%;
  height: fit-content;
  display: grid;
  align-items: center;
  grid-template-columns: 1.5fr 1.5fr 1.5fr 0.7fr;
  gap:20px;
}
.bottomChartMobile {
  width: 80%;
  margin: 0 auto;
  height: fit-content;
  display: none;
  align-items: center;
  grid-template-columns: 1.5fr 1.5fr 1.5fr 0.7fr;
  gap:20px;
}
.bottomBet {
  display: flex;
}
.highcharts-dark {
  height: 62vh;
}
.bottomButtons {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap:10px;
}
.bottomChangeButtons {
  background-color: #090909;
  border: 1px solid #FFFFFF0D;
  border-radius: 8px;
  padding: 3px 30px 3px 30px;
  text-align: center;
  margin-left: 15px;
  font-size: 16px;
  font-weight: 700;
  height: 25px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bottomChangeButtons:nth-child(1) {
  font-size: 18px;
  font-weight: 700;
}
.bottomBet input {
  background-color: #141414;
  border: 1px solid #FFFFFF0D;
  color: white;
  outline: none;
  border-radius: 8px;
  width: 100%;
  padding: 16px 14px 16px 14px;
  font-size: 16px;
}
.bottomBet input::placeholder {
  color: #727272;
}
.betButtons {
  display: flex;
  gap: 20px;
  font-size: 18px;
  font-weight: 700;
  height: 100%;
}
.betUpButton {
  background-color: #00C896;
  border-radius: 8px;
  padding: 16px 14px 16px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  color: white;
  cursor: pointer;
}
.betDownButton {
  background-color: #ED3232;
  border-radius: 8px;
  padding: 16px 14px 16px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  cursor: pointer;
}
.possibleIncome {
  display: flex;
  flex-direction: column;
  gap:5px;
  text-align: left;
  font-size: 16px;
}
.selectModeChart {
  display: flex;
  font-size: 16px;
  font-weight: 700;
  gap:20px;
}
.selectModeChart p {
  cursor: pointer;
}
.headerRight {
  justify-self: right;
  display: flex;
}
.titleChart {
  margin-top: 2%;
  display: grid;
  grid-template-columns: 0.7fr 1fr;
}
.firstTitleChart {
  display: flex;
  font-weight: 700;
  gap:25px;
}
.secondTitleChart {
  display: flex;
  gap: 20px;
}
.secondTitleChart p{
  font-size: 18px;
}
.typeHeadCandle{
  font-weight: 700;
}
.timeFilter {
  color: #2F80ED !important;
}
.deposit {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 16px 28px 16px 28px;
  color: black;
  font-weight: 700;
  font-size: 18px;
  margin-right: 15px;
  cursor: pointer;
  user-select: none;
}
.greenHeadCandle {
  color: #00C896;
}
.redHeadCandle {
  color: #EB5757;
}
.balance {
  display: flex;
  min-width: 10vw;
  background: #0F0F0F;
  border: 1px solid #FFFFFF0D;
  border-radius: 8px;
  padding: 17px 28px 17px 28px;
  color: rgb(255, 255, 255);
  font-size: 18px;
}
.highcharts-range-selector-buttons {
    display: none;
}
.plotLineInfo {
  background-color: white !important;
}
@media(max-width: 1200px) {
  .titleChart {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 968px) {
  .dashboard {
    display: none;
  }
  .dashboardMobile {
    display: block;
  }
  .bottomChart {
    display: none;
  }
  .bottomChartMobile {
    display: grid;
    grid-template-columns: 1fr;
    gap:5px;
    overflow-y: auto;
    height: 45%;
    scale: 0.9;
  }
  .selectModeChart p {
    color: #C4C4C4;
  }
  .titleChart {
    display: flex;
    flex-direction: column;
  }
  .balance {
    padding: 11px 25px 11px 25px;
  }
  .secondTitleChart {
    display: flex;
    flex-direction: column;
  }
  .secondTitleChart div p:nth-child(1) {
    margin-right:10px;
  }
  .secondTitleChart div p:nth-child(2) {
    margin-right:20px;
  }
  .secondTitleChart div p:nth-child(3) {
    margin-right:10px;
  }
  .secondTitleChart div p:nth-child(4) {
    margin-right:20px;
  }
  .highcharts-dark {
    height: 24vh;
  }
  .firstTitleChart {
    font-size: 18px;
    color: #C4C4C4;
  }
  .typeHeadCandle {
    color: #C4C4C4;
  }
  .betButtons {
    height: 80%;
  }
  .bottomBet {
    flex-direction: column;
    gap: 10px;
  }
  .bottomBet input {
    padding: 11px 20px 11px 20px;
  }
  .bottomButtons {
    flex-direction: row;
    gap: 20px;
  }
  .bottomChangeButtons {
    width:100%;
    margin-left: 0;
  }
  .possibleIncome {
    flex-direction: row;
    gap:15px;
  }
  .deposit {
    margin-right: 0;
  }
  .chart {
    background-size: 200%;
    background-position: 0 5vh;
  }

}
@media (max-height:700px) {
  .bottomChartMobile {
    display: grid;
    grid-template-columns: 1fr;
    gap:10px;
    overflow-y: auto;
    height: 40%;
  }
}
</style>
